@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}
.boxShadow {
    box-shadow: 0px 4px 15px 0px #dde3f5;
}

@layer utilities {
    .popover-content-width-full {
        width: var(--radix-popover-trigger-width);
        max-height: var(--radix-popover-content-available-height);
    }
}

.dropdown-menu-content.full-width {
    width: var(--radix-dropdown-menu-trigger-width);
}
